<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col text-center">
                            <h2 class="mb-0">
                                <b>
                                    {{ tt('advance_search_material') }}
                                </b>
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('service_number')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('mcr_number')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('item_name')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('nato_group_class')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('service_category')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('service_group')"/>
                        </div>
                        <div class="col-6">
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('valuation_class')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('keyword')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('updated_by')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('catalog_type')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('catalog_status')"/>
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('yyyy_mm_dd')"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('long_description')"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <el-collapse accordion class="card">
                                <el-collapse-item title="Source Data" name="1" class="ml-4 mr-3">
                                    <div class="mr-1">
                                        <div class="row">
                                            <div class="col-6">
                                                <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('service_category')"/>
                                            </div>
                                            <div class="col-6">
                                                <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('unit_of_measure')"/>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('service_group')"/>
                                            </div>
                                            <div class="col-6">
                                                <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('valuation_class')"/>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <base-input input-classes="form-control-sm mb--3" v-model="department.search" :placeholder="tt('description')"/>
                                            </div>
                                        </div>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                    <router-link to="/general_layout/search-service-search" class="btn btn-block btn-lg btn-primary pt-3 pb-3">
                        {{ tt('search') }}
                    </router-link>
                    <!-- <base-button type="sm" class="btn btn-block btn-lg btn-primary pt-3 pb-3" size="lg">
                        {{ tt('search') }}
                    </base-button> -->
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('holding') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('holding')" rules="required">
                    <el-select class="select-danger" v-model="department.holding" placeholder="Choose Holding">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="department.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('description')" :placeholder="tt('description')" v-model="department.description" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    export default {        
        data() {
            return {                
                form: {
                    add: true,
                    title: "Create Department",
                    show: false
                }, 
                table: {
                    data: [
                        {
                            id: 1,
                            holding: "Inalum", 
                            code: "Maintenance", 
                            description: "Maintenance",
                        },
                        {
                            id: 2,
                            holding: "Inalum", 
                            code: "Besides Maintenance", 
                            description: "Besides Maintenance",
                        },
                    ]
                },        
                department: {
                    
                },
                activeNames: ['1']
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = "Create Department";
                this.form.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = "Update Department";
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to delete this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed delete data');
                    } else {
                        this.alertDialog('error', 'Failed delete data');
                    }
                })
            },
        }   
    };
</script>
<style></style>
    